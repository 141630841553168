<template>
  <div class="page" ref="refPrint">
    <!-- <div class="inside-page-content">
    <div class="paper">-->
    <div class="notice-title h1">{{title}}</div>

    <div class="h4 time" style="position:relative;">
      {{formatTime}}
      <div class="btns">
        <el-button type="text" icon="el-icon-position" @click="share">分享</el-button>
        <el-button type="text" icon="el-icon-printer" @click="print">打印</el-button>
      </div>
    </div>

    <div class="divider-bottom"></div>
    <div style="display: flex;justify-content: center;">
      <img
        v-if="detailType=='soli-yuansuo'||detailType=='soli-anli'"
        :src="detailData.pictureUrl"
        style="width: 90%;object-fit: fill;"
      />
    </div>
    <div class="notice-content" v-html="content" v-if="detailType!='soli-anli'"></div>
    <div v-if="detailType=='soli-anli'">
      <div style="margin-top:20px;">落地项目:{{ detailData.landingProject }}</div>
      <div style="margin-top:20px;">项目投资金额:{{ detailData.investmentAmount }}</div>
      <div style="margin-top:20px;">内容:{{ detailData.content }}</div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      article_id: null,
      //soli-yuansuo 入住院所（不显示时间）,soli-anli 案例 ,zhaoyinzhengce 招引政策
      detailType: 'default',
      detailData: {
        'title': '',
        'content': ''
      },
    }
  },
  computed: {
    title() {
      var title = this.detailData.title
      if (title == null) {
        title = this.detailData.enterpriseName
      }
      if (title == null) {
        title = this.detailData.institutesName
      }
      return title ?? '--'
    },
    content() {
      var content = this.detailData.content
      if (content == null) {
        content = this.detailData.institutesDetail
      }
      return content ?? '--'
    },
    formatTime() {
      var time = '--'
      if (this.detailData.createTime) {
        time = this.detailData.createTime.substring(0, 10)
      }
      if (this.detailData.time) {
        time = this.detailData.time.substring(0, 10)
      }

      if (this.detailType == 'soli-anli') {
        time = '签约：' + time
      }
      else {
        time = '时间：' + time
      }
      return time
    },
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const article_id = this.$route.query?.id
    if (article_id) {
      this.article_id = article_id
    }
    const detailType = this.$route.query?.detailType
    if (detailType) {
      this.detailType = detailType
    }
    if (this.detailType == 'policyHall-dongtai') {
      this.$api.policyHall.informationDetail({ 'id': this.article_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
    else if (this.detailType == 'policyHall-policy') {
      this.$api.policyHall.infoDetail({ 'id': this.article_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
    else if (this.detailType == 'ds-notice') {
      this.$api.digitalServices.announcementDetail({ 'id': this.article_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
    else if (this.detailType == 'soli-notice') {
      this.$api.solicitationCloud.announcementDetail({ 'id': this.article_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
    else if (this.detailType == 'soli-policy') {
      this.$api.solicitationCloud.institutesPolicyDetail({ 'id': this.article_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
    else if (this.detailType == 'soli-yuansuo') {
      this.$api.solicitationCloud.institutesInfoDetail({ 'id': this.article_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
    else if (this.detailType == 'soli-anli') {
      this.$api.solicitationCloud.businessExampleDetail({ 'id': this.article_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
    else if (this.detailType == 'soli-zhaoyinzhengce') {
      this.$api.solicitationCloud.businessPolicyDetail({ 'id': this.article_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
    else if (this.detailType == 'soli-zhaocaizhengce') {
      this.$api.solicitationCloud.talentPolicyDetail({ 'id': this.article_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
    else if (this.detailType == 'soli-ysxzpolicy') {
      this.$api.solicitationCloud.expertPolicyDetail({ 'id': this.article_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
    else if (this.detailType == 'soli-jjxzpolicy') {
      this.$api.solicitationCloud.fundPolicyDetail({ 'id': this.article_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
  },
  mounted() {

  },
  methods: {
    share() {
      let Url = location.href;
      let oInput = document.createElement('input');
      oInput.value = Url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy");
      // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.style.display = 'none';
      this.$message.success("成功复制链接，请去目标位置粘贴")
    },
    print() {
      this.$nextTick(() => {
        let styleStr = "/printCss/commonPrint.css"
        this.$print(this.$refs.refPrint, styleStr);
      })
    },
  }
}
</script>
<style scoped lang="less">
.page {
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
}
.notice-title {
  text-align: center;
  margin-top: 35px;
  font-weight: 700;
}

.time {
  text-align: center;
  margin: 10px 0;
  // padding-bottom: 30px;
  // border-bottom: 2px dashed #cccccc;
}

.divider-bottom {
  width: 100%;
  height: 1px;
  border-bottom: 2px dashed #cccccc;
  margin: 20px 0;
}

.notice-content {
  margin-top: 30px;
  line-height: 40px;
  /deep/.ql-align-center {
    text-align: center !important;
  }
  /deep/ img {
    max-width: 1000px !important;
    object-fit: scale-down;
  }
}
.btns {
  position: absolute;
  top: -9px;
  right: 0;
}
</style>
